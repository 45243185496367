<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryForm" :model="queryForm" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryForm.qstr" placeholder="名稱、備註"></el-input>
        </el-form-item>
        <el-form-item label="顏色">
           <el-checkbox-group v-model="queryForm.type">
              <el-checkbox label="primary" style="color:#409EFF">藍</el-checkbox>
              <el-checkbox label="success" style="color:#67C23A">綠</el-checkbox>
              <el-checkbox label="warning" style="color:#E6A23C">橙</el-checkbox>
              <el-checkbox label="danger" style="color:#F56C6C">紅</el-checkbox>
              <el-checkbox label="info" style="color:#909399">灰</el-checkbox>
           </el-checkbox-group>
        </el-form-item>
        <el-form-item class="ml-5">
          <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort,order,queryForm)">查詢</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addTag">新增</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
           :cell-class-name="tableCellClassName"
            :data="tags"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'id', order: 'descending'}"
            @sort-change = "sortChange"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
   
            <el-table-column
                label="名稱"
                sortable = "custom"
                prop="name"
            >
             <template slot-scope="scope">
                     <el-tag :type="scope.row.type">{{scope.row.name}}</el-tag>
             </template>
            </el-table-column>
            <el-table-column
                label="生效日期"
                prop="start_date"
                :formatter="dateFormatter"
                sortable = "custom"
                width="150px"
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="end_date"
                :formatter="dateFormatter"
                sortable = "custom"
                width="150px"
            >
            </el-table-column>
            <el-table-column
                label="備註"
                prop="memo"
                sortable = "custom"
            >
            </el-table-column>
                           <el-table-column
              fixed="right"
              label="操作"
              width="150">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editTag(scope.row.id)"
                  
                  size="mini">
                  編輯
                </el-button>
                <el-button
                  @click.native.prevent="deleteTag(scope.row.id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>

  <el-dialog title="新增標籤" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.end_date"></el-date-picker>
        </el-form-item>
          <el-form-item label="備註" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.memo"></el-input>
        </el-form-item>
          <el-form-item label="顏色" :label-width="formLabelWidth" prop="type">
          <el-radio-group v-model="form.type">
              <el-radio label="primary" style="color:#409EFF">藍</el-radio>
              <el-radio label="success" style="color:#67C23A">綠</el-radio>
              <el-radio label="warning" style="color:#E6A23C">橙</el-radio>
              <el-radio label="danger" style="color:#F56C6C">紅</el-radio>
              <el-radio label="info" style="color:#909399">灰</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'TagList',
  created () {
      this.$store.dispatch('tag/list', {max: this.max, offset: this.offset, sort: this.sort, order: this.order, showExpired: true}, {
          root: true
      });
  },
  data() {
          var validateName = (rule, value, callback) => {
            if (this.form.id) {
                 const data = this.getTagById(this.form.id);
                 if ( data.name === value) {
                   callback();
                   return;
                 }
            } 
            API.tag.search({name: value}).then(res =>{
              if (res.data.length == 0) {
                  callback();
              } else {
                callback(new Error('已存在'));
              } 
            })
        };
        return {
          pageSize:10,
          currentPage:0,
          queryForm: {
            qstr:'',
            type:[]
          },
          form: {
            id: null,
            name:'',
            memo:'',
            type:'',
            start_date: null,
            end_date: null,
          },
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
              { validator: validateName, trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
            type: [
              { required: true, message: '請選擇顏色', trigger: 'blur' },
            ]
          },
        }
  },
  methods:{
     tableCellClassName({row, column, rowIndex, columnIndex}) {
        const now = new Date().getTime();
        if(column.property === 'start_date'){
          if (row.start_date > now) {
            return 'warning-cell';
          } 
        }
        if(column.property === 'end_date'){
          if(row.end_date < now){
             return 'warning-cell';
          }
        }
        return '';
      },
    addTag() {
      if(this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form.id=null;
      this.form.name='';
      this.form.memo='';
      this.form.type=null;
      this.form.start_date = moment().startOf('day').toDate().getTime();
      this.form.end_date= null;
    this.dialogFormVisible = true
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset,this.sort,this.order,this.queryForm);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1),this.sort,this.order,this.queryForm);

      },
    query(max,offset, sort, order, params = {}) {
      this.$store.dispatch('tag/list', {max: max, offset: offset, sort: sort, order: order, showExpired: true, ...params}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
     editTag(tag_id) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
       const data = this.getTagById(tag_id);
       this.form.id=data.id;
        this.form.name=data.name;
        this.form.memo=data.memo;
        this.form.type=data.type;
        this.form.start_date= data.start_date;
        this.form.end_date= data.end_date;
       this.dialogFormVisible = true;
    },
    deleteTag(tag_id) {
      this.$confirm('此操作將永久刪除該標籤, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('tag/remove', tag_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitAdd() {
      this.$store.dispatch('tag/add', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        this.form.name='';
        this.form.memo='';
        this.form.type='';
        this.form.start_date= null;
        this.form.end_date= null;
        this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
      })
    },
    submitUpdate() {
      this.$store.dispatch('tag/update', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        this.form.name='';
        this.form.memo='';
        this.form.type='';
        this.form.start_date= null;
        this.form.end_date= null;
        this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
      })
    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('tag/SET_SORT','id',{root: true})
        this.$store.commit('tag/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('tag/SET_SORT',args.prop,{root: true})
        this.$store.commit('tag/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order,this.queryForm)
    },
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          tags: 'tag/tags',
          total:'tag/total',
          max:'tag/max',
          offset:'tag/offset',
          getTagById: 'tag/getTagById',
          sort: 'tag/sort',
          order: 'tag/order'
      })
  },
}
</script>
<style scoped>
  .el-table >>> .warning-row {
    background: oldlace;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
</style>
